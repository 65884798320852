import {
  SET_ACTIVE_ICON,
  REMOVE_ACTIVE_ICON,
} from '../constants/utilsConstants';

const initialState = {
  isIconActive: false,
};

function setActiveIcon(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_ACTIVE_ICON:
      return { ...state, ...payload, isIconActive: true };
    case REMOVE_ACTIVE_ICON:
      return { ...state, ...payload, isIconActive: false };
    default:
      return state;
  }
}

export default setActiveIcon;
